const LOCAL_STORAGE_KEY = 'ellavid';


function isAvailable() {
  try {
    localStorage.getItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

function has(key) {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEY + '.' + key) != null;
  } catch (e) {
    return false;
  }
}

function get(key) {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY + '.' + key);
    try {
      return (value == null) ? null : JSON.parse(value);
    } catch (e) {
      // Non-JSON item in localStorage - not allowed
      localStorage.removeItem(LOCAL_STORAGE_KEY + '.' + key);
    }
  } catch (e) {
    // localStorage is not available
  }
  return null;
}

function set(key, value) {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY + '.' + key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
}

function remove(key) {
  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY + '.' + key);
    return true;
  } catch (e) {
    return false;
  }
}

function clear() {
  try {
    localStorage.clear();
    return true;
  } catch (e) {
    return false;
  }
}

export default { has, get, set, remove, clear, isAvailable };
