import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import(/* webpackChunkName: "platform" */ '../views/Platform.vue')
  },
  {
    path: '/use-case/:useCaseId',
    name: 'UseCase',
    props: true,
    component: () => import(/* webpackChunkName: "use-case" */ '../views/UseCase.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "route-not-found" */ '../views/RouteNotFound.vue')
  }
];

function scrollBehavior(to, from, savedPosition) {
  if ((to.name == from.name) && !to.hash && from.hash)
    return;
  if (to.hash)
    return { selector: to.hash };
  return { x: 0, y: 0 };
}

const router = new VueRouter({ mode: 'history', routes, scrollBehavior });

router.beforeEach((to, from, next) => {
  let title = to.name;
  for (const param of Object.values(to.params))
    title += ' | ' + param;
  document.title = title;
  next();
});

export default router;
