<template>
  <v-dialog :value="true" max-width="300" :persistent="persistent" @input="dismiss">
    <v-card>
      <template v-if="title">
        <v-card-title v-html="title" />
        <v-divider />
      </template>
      <template v-if="msg">
        <v-card-text class="pt-4" v-html="msg" />
        <v-divider />
      </template>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-for="btn in buttons" :key="btn"
          text
          :color="btn == primary ? 'primary' : null"
          @click="resolve(btn)"
        >
          {{btn}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: { type: String },
    msg: { type: String },
    buttons: { type: Array, default: () => [ 'No', 'Yes' ] },
    primary: { type: String, default: 'Yes' },
    persistent: { type: Boolean, default: true },
    resolve: { type: Function }
  },

  mounted () {
    document.addEventListener('keyup', this.onKeyUp)
  },

  destroyed () {
    document.removeEventListener('keyup', this.onKeyUp)
  },

  methods: {
    dismiss() {
      this.resolve(this.primary);
    },

    onKeyUp(e) {
      if (e.keyCode == 13 && this.buttons.indexOf(this.primary) != -1) {
        e.stopPropagation();
        this.resolve(this.primary);
      }
    }
  }
}
</script>
