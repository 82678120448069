export default t => {
  if (!t || t == Number.POSITIVE_INFINITY || t == Number.NEGATIVE_INFINITY)
    return '0:00';
  const h = Math.floor(t / 3600);
  const m = Math.floor((t % 3600) / 60);
  const ss = ('0' + Math.round(t % 60)).slice(-2);
  if (h)
    return `${h}:${('0' + m).slice(-2)}:${ss}`;
  return `${m}:${ss}`;
};
