import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import variables from '@/styles/variables.scss';


Vue.use(Vuetify);


export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: variables
    }
  }
});
