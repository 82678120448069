class VisibleError extends Error {
  constructor(msg, type='warning', showContactUs=false) {
    // 'type' should be one of 'error' or 'warning'
    super(msg);
    this.type = type;
    this.showContactUs = showContactUs;
  }
}
window.VisibleError = VisibleError;


import '@/styles/app.scss';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import asyncStatus from '@/mixins/async-status.js';
import confirm from '@/mixins/confirm.js';
import globalStorage from '@/mixins/global-storage.js';
import opDevCall from '@/mixins/op-dev-call.js';
import vuetify from '@/plugins/vuetify';

Vue.mixin(asyncStatus);
Vue.mixin(confirm);
Vue.mixin(globalStorage);
Vue.mixin(opDevCall);

new Vue({ vuetify, router, render: h => h(App) }).$mount('#app');
