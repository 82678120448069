<template>
  <v-app>

    <v-app-bar app clipped-right class="align-center" style="right: 0 !important; z-index: 201;">
      <logo fill />
      <v-spacer />
      <v-btn class="hidden-sm-and-down pa-2" text to="/">Home</v-btn>
      <v-btn class="hidden-sm-and-down" text to="/platform">Platform</v-btn>
      <!-- <v-btn class="hidden-sm-and-down" text to="api">API</v-btn> -->
      <!-- <v-btn class="hidden-sm-and-down" text to="/demo">Demo</v-btn> -->
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="hidden-sm-and-down" text v-bind="attrs" v-on="on">Use cases <v-icon v-html="mdiMenuDown" /></v-btn>
        </template>
        <v-list>
          <v-list-item dense v-for="item in USE_CASES" :key="'nav:' + item.id" :to="{ name: 'UseCase', params: { useCaseId: item.id } }">
            <v-list-item-title class="overline" v-html="item.title" />
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn class="hidden-sm-and-down" text href="mailto:contact@ellacard.com"><v-icon class="mr-2" v-html="mdiEmail" />contact@ellacard.com</v-btn>
      <v-btn class="hidden-md-and-up pa-2" text :input-value="showNavMenu" @click.stop="showNavMenu = !showNavMenu">
        <v-icon v-html="mdiMenu" />
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app right disable-resize-watcher color="rgb(237,246,252)" style="z-index: 202;" v-model="showNavMenu">
      <v-list>
        <v-list-item to="/">Home</v-list-item>
        <v-list-item to="/platform">Platform</v-list-item>
        <!-- <v-list-item to="/demo">Demo</v-list-item> -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Use cases</v-list-item-title>
          </template>
          <v-list-item dense v-for="item in USE_CASES" :key="'side-nav:' + item.id" :to="{ name: 'UseCase', params: { useCaseId: item.id } }">
            <v-list-item-title class="ml-4" v-html="item.title" />
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-item href="mailto:contact@ellacard.com">
          <v-list-item-action>
            <v-icon v-html="mdiEmail" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact us</v-list-item-title>
            <v-list-item-subtitle>contact@ellacard.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer dark>
      <div class="flex-grow-1 overline">
        <div class="my-2 float-right">&copy; 2021</div>
        <div class="my-2 white--text">Ellacard, LLC</div>
        <div class="mx-n2 d-flex flex-wrap">
          <a class="ma-2 white--text" href="https://ellacard.com/privacy-policy">Privacy Policy</a>
          <a class="ma-2 white--text" href="https://ellacard.com/terms-and-conditions">Terms and Conditions</a>
          <a class="ma-2 white--text" href="mailto:contact@ellacard.com">contact@ellacard.com</a>
        </div>
        <div class="mx-n2 d-flex flex-wrap">
          <v-btn class="ma-2" icon href="https://www.facebook.com/ellacard/" target="_blank">
            <v-icon>{{mdiFacebook}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon href="https://instagram.com/ellacard_co" target="_blank">
            <v-icon>{{mdiInstagram}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon href="https://www.linkedin.com/company/ellacard" target="_blank">
            <v-icon>{{mdiLinkedin}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon href="https://www.pinterest.com/ellacard_co/" target="_blank">
            <v-icon>{{mdiPinterest}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon href="https://twitter.com/ellacard_co" target="_blank">
            <v-icon>{{mdiTwitter}}</v-icon>
          </v-btn>
        </div>
        <div class="my-6 d-flex align-center">
          <div class="overline mr-2">Powered by</div>
          <a href="https://ellacard.com" target="_blank" alt="Ellacard" title="Ellacard">
            <img class="d-block" height="48" src="https://ellacard.com/ellacard-logo.png" />
          </a>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>


<style>
header > .v-toolbar__content .v-btn.v-btn--text.v-size--default {
  min-width: unset;
  margin: 0 4px;
}
</style>


<script>
import Logo from '@/components/Logo.vue';
import USE_CASES from '@/data/use-cases.js';
import store from '@/utils/local-storage.js';
import { mdiEmail, mdiMenu, mdiMenuDown, mdiFacebook, mdiInstagram, mdiLinkedin, mdiPinterest, mdiTwitter } from '@mdi/js';


export default {
  name: 'app',

  components: {
    Logo
  },

  data() {
    return {
      showNavMenu: false
    };
  },

  created() {
    Object.assign(this, { USE_CASES, mdiEmail, mdiMenu, mdiMenuDown, mdiFacebook, mdiInstagram, mdiLinkedin, mdiPinterest, mdiTwitter });
  },

  methods: {
  },

  watch: {
    'global.projects'(v) {
      store.set('projects', v);
    }
  }
};
</script>
