// Note: if you update these, make sure to also update the sitemap.xml

const USE_CASES = [

  {
    id: 'tutorials',
    title: 'Branded tutorials',
    desc: `Video tutorials of your product or service are now a must have to stay competitive. More than 86% of businesses use video content, and 93% of marketers who use video say that it's an important part of their strategy. Now you can set yourself above the rest with our personalized branded tutorial templates. Add custom text, images, audio, and animations that personalize your video to a specific individual but still make it look professional. Now when a customer or sales prospect requests a product tutorial or demo, you can personalize it to them in minutes, and they will feel truly valued.`,
    demos: []
  },

  {
    id: 'donor-appreciation',
    title: 'Donor appreciation',
    desc: `If you manage a large audience of donors, personalization is the key to building and maintaining a close relationship with them. Your donors will feel most engaged when receiving content personalized to them from the people that are benefiting from their contributions. With Ellacard, you can create high-quality, personalized and collaborative videos efficiently at scale. Our video platform automatically edits together videos, pictures, and text from multiple contributors into a well-formatted, personalized video.`,
    demos: [
      {
        title: 'Animal shelter',
        desc: `As soon as a donor generously gives to your shelter, reward them with a personalized thank you video. Simply add their name, and have one or several staff members record a thank you video message. Finally, add some photos of the cute animals that their donation is going towards helping. Your donor will absolutely love the personalized thank you.`,
        id: '0e45458b-6f56-4252-8d99-28882e7403f8',
        poster: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/0e45458b-6f56-4252-8d99-28882e7403f8/poster_7f53edbf-a0d3-4cef-b103-3de705a2f42c.jpg',
        src: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/0e45458b-6f56-4252-8d99-28882e7403f8/render/5.mp4'
      },
      {
        title: 'School giving day',
        desc: `With our sample school donor video, a school can quickly create thousands of videos that are personalized to each and every alumni. Instead of sending one generalized video, you can easily make several versions of a video with general video messages from old professors, and personal video messages from students that the alumni can relate to. This video template will remind each and every alumnus that they are a valued member of the community, and of how important their donations are.`,
        id: 'd076d00d-a9aa-47e3-bf49-a41299892ad7',
        poster: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/d076d00d-a9aa-47e3-bf49-a41299892ad7/poster_03cf54c7-f3b9-4b70-ba6b-b412de8ac9fb.jpg',
        src: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/d076d00d-a9aa-47e3-bf49-a41299892ad7/render/8.mp4'
      }
    ]
  },

  {
    id: 'forms',
    title: 'Forms',
    desc: `Use our video templates as forms to collect multi-media inputs from users. Go beyond text and checkboxes with our audio, video, image, and sortable image group inputs. Conditionally hide and show inputs, or propagate data between them. Built-in editing tools make it even easier for users to submit data. We take care of converting and optimizing all multi-media responses so they can be viewed from any device. View and download individual responses, or instantly aggregate customer responses into a video.`,
    demos: []
  },

  {
    id: 'memorials',
    title: 'Memorials',
    desc: `Your church has a tight-nit community. Or maybe you run a funeral home. You want to make it possible for the bereaved to make a touching video memorializing the deceased. Embed the entire interface directly in your own website with your own custom branding.`,
    demos: [
      {
        title: 'Candlelight vigil',
        desc: `Recreate a candlelight vigil for the deceased with this beautiful slideshow theme. Personalize your video with ease using our no-edit interface and invite friends and family to contribute their favorite photos. Once complete, your video can be shared on any platform for a beautiful keepsake for all to remember.`,
        id: '9c5652ff-e923-40c2-9147-7837b9c00baf',
        poster: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/9c5652ff-e923-40c2-9147-7837b9c00baf/poster_233184f7-2c5d-4ba1-9db5-f269048340a8.jpg',
        src: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video/fc24c7c0-c9c3-4829-84a6-ad6e58230e0f/r/16642850-1a78-4f8a-a434-22a2fc08d5c6.mp4'
      },
      {
        title: 'In loving memory',
        desc: `This theme is perfect for creating a premium quality memorial video that will stun everyone with its beauty. Quickly personalize your video and it will artfully edit together your photos and videos into a thoughtful production that will look like it was professionally made. As with all of our themes, you can invite anyone to contribute, and no video experience is needed.`,
        id: '19f2a983-2632-4b79-9b17-551fbc3c4489',
        poster: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/19f2a983-2632-4b79-9b17-551fbc3c4489/poster_3b8f95a2-035a-41d8-ae97-557e353b9327.jpg',
        src: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video/19a472c6-bfa6-4c9c-af8b-912f543ca4a1/r/27a57265-740e-4380-ba22-2f29d11493bd.mp4'
      },
      {
        title: 'Ken Burns slideshow',
        desc: `Our Ken Burns slideshow gives you the ability to create a beautiful memorial video with gentle panning. With our no-edit technology you can easily personalize your video and invite others to contribute their own pictures with captions! Simple to create and beautiful to watch, this memorial slideshow will serve as a thoughtful memory of the deceased for all to enjoy.`,
        id: '1377e400-be0b-4434-8843-beba852f8124',
        poster: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/1377e400-be0b-4434-8843-beba852f8124/poster_1142b4c5-331b-455b-92a9-8ff6a0d2fd23.jpg',
        src: 'https://d3ivhmcqxvlmhm.cloudfront.net/group-video-template/1377e400-be0b-4434-8843-beba852f8124/render/7.mp4'
      }
    ]
  },

  {
    id: 'outreach',
    title: 'Personalized outreach',
    desc: `Traditional emails are too easy to ignore. Use videos to reach out to your customers. Set high standards for content, and consistently deliver, all without spending time / money on video production. Deliver your videos embedded in Ellacards for an even more engaging experience.`,
    demos: []
  },

  {
    id: 'product-reviews',
    title: 'Product reviews',
    desc: `Text and photos reviews are just not enough to differentiate your product in today's competitive environment where thousands of new products go live every day. With the average user spending 88% more time on a product listing with video, and ⅓ of all online activity being dedicated to watching video, it is time to make your product stand out with video reviews that will leave an enduring impact on your viewers. With Ellacard you give every customer the tools to quickly and easily create a high-quality review video without having to do any editing. With our unique video templating system, you can ask specific questions of your customers, and maintain uniformity so your users can watch multiple videos with ease. Give your product the best chance with Ellacard video reviews.`,
    demos: []
  },

  {
    id: 'professional-services',
    title: 'Professional services',
    desc: `As a service professional, your relationship with your customers and followers is vital to your business. Video content has become a crucial tool in your arsenal to maintain and build these important relationships. With Ellacard, professional quality video content can now be created with no video editing experience. Regularly releasing videos can position you as a market expert, expose your brand to a wider audience, and help clients navigate tricky topics. With our software, you can also personalize your outreach to specific prospects or customers. Answer a question, offer advice, or explain an idea in a matter of minutes. Clients will appreciate these personal touch points, and you will build a stronger business.`,
    demos: []
  },

  {
    id: 'real-estate',
    title: 'Real estate',
    desc: `Videos have become an important tool in the real estate agent's arsenal. Regularly releasing content will position an agent as a market expert, expose listings to a wider audience, and help clients navigate their purchase journey. With Ellacard, agents can transform their content creation with personalized videos. With our software, an agent can answer a question, offer advice, or give comparative market analysis on a property, in a matter of minutes. Clients will appreciate the informative content and the personalized yet professional quality video.`,
    demos: []
  },

  {
    id: 'recruiting',
    title: 'Recruiting',
    desc: `Recruitment videos have become an increasingly important medium for companies and schools to attract great candidates. They allow you to show off the company and its people by showing candidates non-verbal cues that they would not otherwise see with text. Don't just tell a candidate your organization's values, create a video full of real examples and build a connection with your candidate. Ellacard makes it easy to mix videos, text, and images so you can produce videos personalized to every candidate in minutes.`,
    demos: []
  }
];

export default USE_CASES;
