<template>
  <div class="root relative" @click="!disabled && $emit('update:paused', !paused)">
    <div class="main-container">
      <slot></slot>
    </div>
    <div class="msg-container" v-bind:class="{ hide: !paused }" style="top: 0;" @click.stop>
      <slot name="header"></slot>
    </div>
    <div class="msg-container" v-bind:class="{ hide: !paused }" style="bottom: 0;" @click.stop>
      <slot name="fixed-footer"></slot>
      <div class="d-flex align-center">
        <v-btn icon dark class="ma-1" :disabled="disabled" :loading="loading" @click="$emit('update:paused', !paused)">
          <v-icon v-if="paused">{{mdiPlay}}</v-icon>
          <v-icon v-else>{{mdiPause}}</v-icon>
        </v-btn>
        <div class="white--text">{{t | video-timestamp}}</div>
        <v-slider
          class="px-3"
          hide-details step="0" :max="duration"
          :disabled="disabled"
          :value="t"
          @input="t => $emit('update:t', t)"
          @mousedown="$emit('update:paused', true)"
          @touchstart="$emit('update:paused', true)"
        />
        <div class="pr-4 white--text">{{duration | video-timestamp}}</div>
        <v-btn v-if="allowFullscreen" icon dark class="ma-1" @click="toggleFullscreen">
          <v-icon v-if="isFullscreen">{{mdiFullscreenExit}}</v-icon>
          <v-icon v-else>{{mdiFullscreen}}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.root:fullscreen {
  width: 100%;
  height: 100%;
  background-color: black;
}
.root:fullscreen > .main-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100vw; 
  max-width: 177.8vh;
  height: 56.25vw;
  max-height: 100vh;
}

.msg-container {
  position: absolute;
  width: 100%;
  min-width: 240px;
  background-color: rgba(0,0,0,.5);
  max-height: 100%;
}
.msg-container.hide {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: opacity 1s, visibility 0s 1s, max-height 0s 1s;
}
</style>

<script>
import videoTimestamp from '@/filters/video-timestamp.js';
import { mdiPlay, mdiPause, mdiFullscreen, mdiFullscreenExit } from '@mdi/js';


function openFullscreen(el) {
  if (el.requestFullscreen) return el.requestFullscreen();
  if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen();
  if (el.webkitEnterFullscreen) return el.webkitEnterFullscreen();
}

function closeFullscreen() {
  if (document.exitFullscreen) return document.exitFullscreen();
  if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
}


export default {
  name: 'VideoOverlay',

  filters: {
    videoTimestamp
  },

  props: {
    paused: Boolean,
    t: Number,
    duration: Number,
    allowFullscreen: Boolean,
    fullscreenEl: HTMLElement,
    disabled: Boolean,
    loading: Boolean
  },

  data() {
    return {
      isFullscreen: false
    }
  },

  created() {
    Object.assign(this, { mdiPlay, mdiPause, mdiFullscreen, mdiFullscreenExit });
    document.addEventListener('fullscreenchange', this.onFullScreenChange);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange);
  },

  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullScreenChange);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenChange);
  },

  methods: {
    onFullScreenChange(e) {
      this.isFullscreen = document.fullscreenElement || document.webkitFullscreenElement;
    },

    toggleFullscreen() {
      const el = this.fullscreenEl || this.$el;
      this.isFullscreen ? closeFullscreen() : openFullscreen(el);
    }
  }
}
</script>
