<template>
  <v-card class="pa-6">
    <v-avatar class="d-block mx-auto mb-6" size="64px">
      <img :alt="author" :src="img" />
    </v-avatar>
    <span class="quoted ec-dark-blue--text" v-html="text" />
    <div class="mt-6 text-center font-italic ec-dark-blue--text" v-html="author" />
  </v-card>
</template>


<style scoped lang="scss">
.quoted {
  position: relative;
  z-index: 1;
  font-style: italic;
  padding-left: 28px;
}
.quoted:before, .quoted:after {
  position: absolute;
  font-size: 6rem !important;
  font-weight: 300;
  line-height: 0px;
  color: $ec-light-blue;
  z-index: -1;
}
.quoted:before {
  top: 34px;
  left: -24px;
  content: '“';
}
.quoted:after {
  right: -28px;
  bottom: -18px;
  content: '”';
}
</style>


<script>
export default {
  name: 'QuoteCard',
  props: [ 'img', 'text', 'author' ]
}
</script>
