import AsyncStatus from '@/utils/async-status.js';
import AsyncStatusErrorDialog from '@/components/AsyncStatusErrorDialog.vue';
import vuetify from '@/plugins/vuetify';
import Vue from 'vue';

export default {
  destroyed() {
    for (const i of (this._trackedErrorDialogs || [])) {
      i.$destroy();
      i.$el.remove();
    }
  },

  methods: {
    $track(key, showErrors=true) {
      const kernel = this[key];
      const status = new AsyncStatus();
      this[key] = function() {
        // It's important to have a function closure here to capture the arguments
        return status.track(() => kernel(...arguments));
      };

      if (showErrors) {
        const ComponentClass = Vue.extend(Object.assign({ vuetify }, AsyncStatusErrorDialog));
        const instance = new ComponentClass({ propsData: { status } });
        instance.$mount();
        if (!this._trackedErrorDialogs)
          this._trackedErrorDialogs = [];
        this._trackedErrorDialogs.push(instance);
        this.$root.$el.appendChild(instance.$el);
      }

      return status;
    } 
  }
};
