import store from '@/utils/local-storage.js';

// This is a simple version of vuex - rather than trying to manage an entire global state with getters / setters for
// everything, we have a simple 'global' mixin, and simply set properties as needed at this top level. It is important
// that this dictionary is a singleton as opposed to being defined in the mixin's 'data' method.
const GLOBAL = {
  projects: {} // A map of template ID -> project ID (see UseCase view)
};

// These values will be synchronized with localStorage if possible
store.PERSIST_LOCAL_STORAGE_KEYS = [
  'projects'
];

function setFromLocalStorage() {
  for (const key of store.PERSIST_LOCAL_STORAGE_KEYS)
    if (store.has(key))
      GLOBAL[key] = store.get(key);
}
setFromLocalStorage();

// The 'storage' event should only be triggered by other browser windows / tabs
addEventListener('storage', setFromLocalStorage);

store.clear();

export default {
  data() {
    return { global: GLOBAL };
  }
};
