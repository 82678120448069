<template>
  <video-overlay
    :disabled="loading || !didPlay"
    :paused.sync="paused"
    :t.sync="t"
    :duration="duration"
    :allow-fullscreen="allowFullscreen && !error"
    :fullscreen-el="$refs.video"
  >

    <template v-slot:header>
      <slot style="z-index: 2;"></slot>
    </template>

    <!-- Default slot -->
    <v-responsive :aspect-ratio="16/9" class="black">

      <div v-if="error" class="absolute-fill d-flex justify-center align-center text-center" style="z-index: 0;" v-html="error" />

      <template v-else>
        <div v-if="loading || !didPlay" class="absolute-fill d-flex justify-center align-center">
          <v-progress-circular v-if="loading" indeterminate />
          <v-btn v-else fab x-large color="primary" style="z-index: 1;" @click="didPlay = true">
            <v-icon x-large>{{mdiPlay}}</v-icon>
          </v-btn>
        </div>

        <video
          ref="video"
          class="d-block"
          width="100%" height="100%" playsinline
          :src="src"
          :poster="poster"
          @durationchange="e => duration = isFinite(e.target.duration) ? e.target.duration : 0"
          @ended="paused = true"
          @timeupdate="e => t = e.target.currentTime"
          @play="paused = false"
          @pause="paused = true"
        />
      </template>
    </v-responsive>
  </video-overlay>
</template>

<script>
import VideoOverlay from '@/components/VideoOverlay.vue';
import { mdiPlay } from '@mdi/js';

export default {
  name: 'VideoPlayer',

  components: {
    VideoOverlay
  },

  props: { src: String, poster: String, allowFullscreen: Boolean, error: String },

  data () {
    return {
      paused: true,
      t: 0,
      duration: 0,
      didPlay: false,
      mdiPlay
    }
  },

  computed: {
    loading() {
      return !this.src || this.duration == 0;
    }
  },

  watch: {
    paused(paused) {
      if (paused) {
        this.$refs.video.pause();
      } else {
        this.$refs.video.play();
        this.didPlay = true;
      }
    },

    t(t) {
      if (this.paused)
        this.$refs.video.currentTime = t;
    }
  }
}
</script>
