import globalStorage from '@/mixins/global-storage.js';
import { opDevFetch, OPError } from '@/utils/op-dev-fetch';


const rootURL = process.env.OP_DEV_CONFIG['service-url'].replace(/\/$/, '');

const active = { t: null, n: 0 };

let refreshJWTPromise = null;

export default {
  mixins: [ globalStorage ],

  methods: {
    async $refreshJWT() {
      if (refreshJWTPromise) {
        await refreshJWTPromise;
        return;
      }
      refreshJWTPromise = this._refreshJWTKernel();
      return await refreshJWTPromise;
    },

    async _refreshJWTKernel() {
      const params = { method: 'REFRESH', payload: this.global.jwt };
      try {
        this.global.jwt = await opDevFetch(rootURL + '/get-jwt', this.global.jwt, params);
      } catch (e) {
        if (e.type == 'AuthenticationException')
          this.global.jwt = null;
        throw e;
      } finally {
        refreshJWTPromise = null;
      }
    },

    async $call(fn, data=undefined) {
      try {
        const now = new Date();
        if (!active.t || (now - active.t) > 10000) {
          // The last request was made over 10 seconds ago, so we're good to go
          active.t = now;
          active.n = 0;
        }
        active.n++;
        if (active.n >= 15) {
          // We've made at least 15 calls in the last ten seconds, slow down
          const delay = 10000 + (active.t - now);
          console.warn('Call-rate exceeded, throttling...');
          await new Promise(r => setTimeout(r, delay));
          return await this.$call(fn, data);
        }

        if (this.global.profile && this.global.profile.exp < (now.getTime() / 1000) + 10)
          // Our token is expired (allowing for 10s of clock drift)
          try {
            await this.$refreshJWT();
          } catch (e) {}

        const url = rootURL + '/' + fn.replace(/^\//, '');
        try {
          return await opDevFetch(url, this.global.jwt, data);
        } catch (e) {
          if (e instanceof OPError && e.type == 'ExpiredToken') {
            // Presumably, this shouldn't happen since we pre-emptively check for expired tokens, but could be possible
            // in the case of extreme clock drift
            try {
              await this.$refreshJWT();
            } catch (e2) {
              throw e;
            }
            return await opDevFetch(url, this.global.jwt, data);
          }
          throw e;
        }
      } catch (e) {
        let description = e.message || `${e.url} - ${e.status}`;
        gtag('event', 'exception', { description });
        throw e;
      }
    }
  }
};
