<template>
  <div>

    <video-dialog
      hash="#tutorial"
      poster="https://ellacard.com/cdn/group_video_editor_tutorial_poster.jpg"
      src="https://ellacard.com/cdn/group_video_editor_tutorial_720.mp4"
    />

    <div class="ec-dark-blue white--text py-6 py-sm-12">
      <v-container>
        <v-responsive class="float-sm-right ml-sm-6 mb-6" :width="$vuetify.breakpoint.xsOnly ? '100%' : '40%'" :aspect-ratio="16 / 9">
          <v-card>
            <video
              class="d-block"
              width="100%"
              playsinline autoplay muted loop
              src="/cdn/examples_720x406.mp4"
              srcset="
                /cdn/examples_720x406.mp4 720w,
                /cdn/examples_640x360.mp4 640w,
                /cdn/examples_320x180.mp4 320w
              "
            />
          </v-card>
        </v-responsive>
        <h1 class="text-h3 text-sm-h2 text-lg-h1"><span class="ec-blue--text">Collaborative</span> video creation platform</h1>
        <div class="my-12 text-h5 text-sm-h4">Use our advanced video templates to effortlessly create beautiful, personalized videos</div>
        <div class="ma-n3">
          <v-btn class="ma-3" large :to="{ name: $route.name, hash: '#tutorial' }">
            <v-icon class="mr-2" v-html="mdiVideo" /> Watch demo
          </v-btn>
          <v-btn class="ma-3" large :to="{ name: 'Platform' }">Platform</v-btn>
          <v-btn class="ma-3" large href="mailto:contact@ellacard.com">Contact us</v-btn>
        </div>
        <!-- <div class="mt-12 text-h5"><a class="white--text" href="mailto:contact@ellacard.com">contact@ellacard.com</a></div> -->
        <div style="clear: both;" />
      </v-container>
    </div>

    <div class="py-12 grey lighten-4">
      <v-container>
        <h2 class="text-h3 text-sm-h2">How it works</h2>
        <div>
          <div class="mt-12">
            <div class="text-h5 ec-blue--text">Start with a template</div>
            <div>We'll work with you to build a custom, flexible, reusable video template to suit your specific needs (turnaround times measured in hours), or you can use one off-the-shelf from our catalog. Collect structured input using our advanced dynamic forms, and instantly preview the resulting video.</div>
          </div>
          <div class="mt-12">
            <div class="text-h5 ec-blue--text">Collaborate</div>
            <div>If needed, share your video project with a group of collaborators. The template automatically combines your group's text, images, audio, and video contributions into a single professional-quality video. Record on desktop or mobile, no need to be in the same place or email video files back and forth.</div>
          </div>
          <div class="mt-12">
            <div class="text-h5 ec-blue--text">Finalize</div>
            <div>Review the instant preview and make any adjustments to the inputs. When you're satisfied, lock the project and we'll render it into a single smooth video file that you can download, share, or embed.</div>
          </div>
        </div>
      </v-container>
    </div>

    <div class="py-12 grey darken-3 white--text">
      <v-container>
        <h2 class="text-h3 text-sm-h2">No editing</h2>
        <v-row class="mt-6 text-h6 text-sm-h5">
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-sm-4">Anyone can make videos</h4>
            No prior video editing experience necessary
          </v-col>
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-sm-4">Eliminate mistakes</h4>
            Our templates lay everything out automatically so your videos look perfect
          </v-col>
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-sm-4">Save time and money</h4>
            Avoid endless back-and-forth and revisions with our instant preview
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="py-12 grey lighten-4">
      <v-container>
        <h2 class="text-h3 text-sm-h2">Use cases</h2>
        <div class="mt-6 mb-12">Have an idea that's not listed here? Please contact us: <a href="mailto:contact@ellacard.com">contact@ellacard.com</a></div>
        <v-row>
          <v-col v-for="item in USE_CASES" :key="item.id" cols="6" sm="4">
            <v-card
              class="pa-3 d-flex justify-center align-center text-center"
              style="position: relative;"
              height="72" hover
              :to="{ name: 'UseCase', params: { useCaseId: item.id } }"
            >
              <span>
                <span class="overline mr-1">{{item.title}}</span>
                <v-icon :small="$vuetify.breakpoint.xsOnly" color="primary" v-html="mdiOpenInNew" />
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="py-12 grey darken-3 white--text">
      <v-container>
        <h2 class="text-h3 text-sm-h2">Video creation platform</h2>
        <v-row class="mt-6 mb-12 text-h6 text-sm-h5">
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-4">Custom branding</h4>
            Video creation / presentation is fully white-labeled with tools for adding your own custom branding
          </v-col>
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-4">Embedding</h4>
            Easily embed the full experience, including collaboration logistics, directly in your site
          </v-col>
          <v-col cols="12" sm="4">
            <h4 class="text-h5 text-sm-h4 ec-blue--text font-italic mb-4">API</h4>
            Set up integrations to automatically create and send videos as e-cards
          </v-col>
        </v-row>
        <v-btn large :to="{ name: 'Platform' }">Platform</v-btn>
      </v-container>
    </div>

    <div class="py-12">
      <v-container>
        <div class="text-h3">Ellacard is used and trusted by thousands of individuals and organizations worldwide.</div>
        <div class="mt-6 text-h5">See more of what we can do at <a href="https://ellacard.com">ellacard.com</a></div>
        <v-row class="my-12">
          <v-col v-for="(quote, i) in QUOTES" :key="i" cols="12" sm="4">
            <quote-card class="fill-height" v-bind="quote" />
          </v-col>
        </v-row>
        <div class="text-h5">Contact us: <a href="mailto:contact@ellacard.com">contact@ellacard.com</a></div>
      </v-container>
    </div>

  </div>
</template>

<script>
import QuoteCard from '@/components/QuoteCard.vue';
import VideoDialog from '@/components/VideoDialog.vue';
import USE_CASES from '@/data/use-cases.js';
import imgRob from '@/assets/rob.jpg';
import imgMatt from '@/assets/matt.jpg';
import imgWhitney from '@/assets/whitney.jpg';
import { mdiOpenInNew, mdiVideo } from '@mdi/js';

const QUOTES = [
  {
    img: imgRob,
    text: 'This is a one-of-a-kind tool. You simply won’t find a better way of creating professional, polished videos with less effort anywhere, especially on the web.',
    author: 'Rob Sobecki'
  },
  {
    img: imgMatt,
    text: 'We turned a jumbled mess of content into a beautiful video that you can watch INSTANTLY. It’s not quite magic, but it’s the next best thing!',
    author: 'Matthew Lopes'
  },
  {
    img: imgWhitney,
    text: 'This absolutely blew me away. Easy to use, and the end result was so amazing. It would not have been possible at all without Ellacard.',
    author: 'Whitney Pratt'
  }
];

export default {
  name: 'Home',

  components: {
    QuoteCard,
    VideoDialog
  },

  created() {
    Object.assign(this, { USE_CASES, QUOTES, mdiOpenInNew, mdiVideo });
  }
}
</script>
