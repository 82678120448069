<template>
  <v-dialog width="400" :value="value" @input="reset">
    <v-alert :type="display.type" class="ma-0">
      <template v-slot:close>
      </template>
        <v-btn class="float-right ma-n2" icon @click="reset">
          <v-icon v-html="mdiClose" />
        </v-btn>
      <slot>
        <div v-html="display.message" />
      </slot>
      <div v-if="display.showContactUs" class="mt-4 ml-n10 font-italic black--text caption">Seeing recurrent issues? Let us know: <a href="mailto:support@ellacard.com" class="black--text">support@ellacard.com</a></div>
    </v-alert>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
  name: 'AsyncStatusErrorDialog',

  props: [ 'status' ],

  data() {
    return {
      value: false
    }
  },

  computed: {
    display() {
      const e = this.status.error;
      if (e && e instanceof VisibleError)
        // The error has the 'message', 'type', and 'showContactUs' properties set
        return e;
      return {
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        showContactUs: true
      }
    }
  },

  created() {
    Object.assign(this, { mdiClose });
  },

  methods: {
    reset() {
      this.value = false;
      // Give the dialog time to fade out before we reset the status to ensure our error message doesn't change
      setTimeout(this.status.reset, 1000);
    }
  },

  watch: {
    'status.rejected': {
      immediate: true,
      handler(rejected) {
        this.value = rejected;
      }
    }
  }
};
</script>
